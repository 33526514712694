import React, {useEffect, useState} from "react";
import Axios from "axios";
import {API} from "../../../api/APIClient";
import ModalProgress from "../../common/ModalProgress";
import {CommonUtil} from "../../../utils/CommonUtil";
import {PluginUtil} from "../../../utils/PluginUtil";
import {NotificationManager} from "react-notifications";

export const AIPromptForm = (props) => {

    const {promptId} = props;
    const {callback} = props;

    const [prompt, setPrompt] = useState();

    const [isLoading, setIsLoading] = useState(false);
    const [isUploading, setIsUploading] = useState(false);

    const [formData, setFormData] = useState({});
    const [touched, setTouched] = useState({});
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (promptId) {
            fetchPromptFromNetwork();
        }
    }, []);

    useEffect(() => {
        if (prompt) {
            const newFormData = {
                title : prompt.title,
                content : prompt.content,
                modelName : prompt.modelName
            };
            setFormData(newFormData);
        }
    }, [prompt]);

    function handleCallback(action, data) {
        if (callback) {
            callback(action, data);
        }
    }

    function putFormData(data) {
        if (!CommonUtil.isObject(data)) {
            return;
        }

        const newFormData = CommonUtil.cloneObject(formData);
        const keys = Object.keys(data);

        keys.forEach((key) => {
            const newData = data[key];
            if (newData !== undefined) {
                newFormData[key] = data[key];
            } else {
                delete newFormData[key];
            }
        });

        setFormData(newFormData);
    }

    function handleBlur(event) {
        const name = event.target.name;
        const newTouched = CommonUtil.cloneObject(touched);
        newTouched[name] = true;
        setTouched(touched);
    }

    function handleChange(event) {
        let value = event.target.type === "checkbox" ? (event.target.checked ? 1 : 0) : event.target.value;

        if (event.target.name === PluginUtil.configKeys.WIDGET_EMBED_TYPE) {
            console.log("HANDLE CHANGE WIDGET EMBED TYPE");
            if (event.target.checked) {
                value = PluginUtil.embedTypes.FLOATING;
            } else {
                value = PluginUtil.embedTypes.STATIC;
            }
            console.log("VALUE: ", value);
        }

        let outData = {};
        outData[event.target.name] = value;
        putFormData(outData);
    }

    function handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();

        if (validateForm()) {
            submitPromptOverNetwork();
        }
    }

    function validateForm() {
        let out = true;
        const errors = {};

        const requiredFields = [
            { "key" : "title", "label" : "Title" },
            { "key" : "content", "label" : "Content" }
        ];

        for (let i = 0; i < requiredFields.length; i++) {
            let problem = false;

            const field = requiredFields[i];
            if (formData.hasOwnProperty(field.key)) {
                if (formData[field.key] === undefined || formData[field.key] === null) {
                    problem = true;
                }
            } else {
                problem = true;
            }

            if (problem) {
                out = false;
                errors[field.key] = "Please provide value for '" + field.label + "'";
            }
        }

        return out;
    }

    function fetchPromptFromNetwork() {
        if (isLoading) return;
        setIsLoading(true);

        const data = new FormData();
        data.append("id", promptId);

        Axios.post(API.aiPrompts.getAIPrompt, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setPrompt(resp.data.prompt);
                } else {
                    console.log("ERROR", resp.error);
                }
                setIsLoading(false);
            })
            .catch((e) => {
                console.log(e);
                setIsLoading(false);
            });
    }

    function submitPromptOverNetwork() {
        if (isUploading) return;
        setIsUploading(true);

        const data = new FormData();

        if (promptId) {
            data.append("id", promptId);
        }

        Object.keys(formData).forEach((key) => {
            data.append(key, formData[key]);
        });

        Axios.post(API.aiPrompts.submitAIPrompt, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    handleCallback("submit", resp.data.prompt);
                } else {
                    console.log(API.formatError(r));
                    NotificationManager.error(API.formatError(r));
                }
                setIsUploading(false);
            })
            .catch((e) => {
                console.log(e);
                setIsUploading(false);
            });
    }

    // RENDER

    let modalTitle = "Create Prompt";
    if (promptId) {
        modalTitle = "Edit Prompt";
    }

    return (
        <>
            <ModalProgress show={isUploading} />
            <div className={"modal-head"}>
                <div className={"modal-head"}>
                    <h3>{modalTitle}</h3>
                </div>
            </div>

            <form onSubmit={handleSubmit}>
                <div className={"modal-body"}>
                    <div className="grid-x grid-padding-x">
                        <div className="small-3 cell">
                            <label htmlFor="title" className="text-right middle ">* Title</label>
                        </div>
                        <div className={"small-9 cell " + (CommonUtil.getOrDefault(errors, "title", null) && "error")}>
                            <input
                                type="text"
                                name="title"
                                onChange={handleChange}
                                value={CommonUtil.getOrDefault(formData, "title", "")}
                                onBlur={handleBlur}
                            />

                            <small className="error">{CommonUtil.getOrDefault(errors, "title", "")}</small>
                        </div>
                    </div>

                    <div className="grid-x grid-padding-x">
                        <div className="small-3 cell">
                            <label htmlFor="content" className="text-right middle ">* Content</label>
                        </div>
                        <div
                            className={"small-9 cell " + (CommonUtil.getOrDefault(errors, "content", null) && "error")}>
                            <textarea
                                name="content"
                                onChange={handleChange}
                                rows="9"
                                value={CommonUtil.getOrDefault(formData, "content", "")}
                                onBlur={handleBlur}
                            />

                            <small className="error">{CommonUtil.getOrDefault(errors, "content", "")}</small>
                        </div>
                    </div>

                    <div className="grid-x grid-padding-x">
                        <div className="small-3 cell">
                            <label htmlFor="modelName" className="text-right middle ">* Model Name</label>
                        </div>
                        <div className={"small-9 cell " + (CommonUtil.getOrDefault(errors, "modelName", null) && "error")}>
                            <input
                                type="text"
                                name="modelName"
                                onChange={handleChange}
                                value={CommonUtil.getOrDefault(formData, "modelName", "")}
                                onBlur={handleBlur}
                            />

                            <small className="error">{CommonUtil.getOrDefault(errors, "modelName", "")}</small>
                        </div>
                    </div>
                </div>

                <div className="modal-footer">
                    <div className="grid-x grid-padding-x">
                        <div className="small-12 cell">
                            <div className="btn-wrap">
                                {
                                    <>
                                        <button className="button secondary" type="button"
                                                onClick={() => handleCallback("submit")}>
                                            Cancel
                                        </button>

                                        <button className="button success" type="submit" disabled={!validateForm()}>
                                            Submit
                                        </button>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    )

}