import React, {useEffect, useState} from 'react';
import ModalProgress from "../common/ModalProgress";
import {CommonUtil} from "../../utils/CommonUtil";
import Modal from "react-responsive-modal";
import {PermissionUtil} from "../../utils/PermissionUtil";
import ReactSelect from "../common/ReactSelect";
import Axios from "axios";
import {API} from "../../api/APIClient";
import {NotificationManager} from "react-notifications";
import {AIPromptsTable} from "../chatflows/aiPrompts/AIPromptsTable";
import {MagicButton} from "../common/MagicButton";

import "./KnowledgeDocumentEditor.css";

export const KnowledgeDocumentEditor = (props) => {

    const KNOWLEDGE_CONTENT_TYPE_PLAIN = 1;
    const KNOWLEDGE_CONTENT_TYPE_URL = 2;
    const KNOWLEDGE_CONTENT_TYPE_FILE = 3;

    const KNOWLEDGE_CONTENT_STRATEGY_RAW = 1;
    const KNOWLEDGE_CONTENT_STRATEGY_AI_PROCESSED = 2;

    const KNOWLEDGE_UPDATE_SCHEDULE_MINUTES = 1;
    const KNOWLEDGE_UPDATE_SCHEDULE_HOURS = 60;
    const KNOWLEDGE_UPDATE_SCHEDULE_DAYS = 1440;

    const {knowledgeDocument} = props;
    const {callback} = props;

    const [isLoadingOrganisations, setIsLoadingOrganisations] = useState(false);
    const [isLoadingTypes, setIsLoadingTypes] = useState(false);
    const [isUploading, setIsUploading] = useState(false);

    const [formData, setFormData] = useState({});
    const [organisationOptions, setOrganisationOptions] = useState([]);

    const [typeOptions, setTypeOptions] = useState([]);

    const [touched, setTouched] = useState({});
    const [errors, setErrors] = useState({});
    const [errorMessage, setErrorMessage] = useState(null);

    const [modalOpen, setModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState([]);

    const [contentType, setContentType] = useState(KNOWLEDGE_CONTENT_TYPE_PLAIN);
    const [contentFile, setContentFile] = useState(null);
    const [contentFileIsPDF, setContentFileIsPDF] = useState(false);
    const [contentDidChange, setContentDidChange] = useState(false);

    const [contentUpdateIntervalType, setContentIntervalType] = useState(KNOWLEDGE_UPDATE_SCHEDULE_MINUTES);
    const [contentUpdateIntervalMultiple, setContentUpdateIntervalMultiple] = useState(0);

    const [isAIProcessing, setIsAIProcessing] = useState(false);
    const [aiProcessingError, setAIProcessingError] = useState(null);
    const [autoSubmitTrigger, setAutoSubmitTrigger] = useState(null);
    const [submitDelayProcessing, setSubmitDelayProcessing] = useState(false);

    useEffect(() => {
        if (knowledgeDocument) {
            setFormData(processDocument(knowledgeDocument));
        }

        if (PermissionUtil.allOrgs(PermissionUtil.PermissionCategories.knowledgeDocuments)) {
            fetchOrganisationsOverNetwork();
            fetchTypesOverNetwork();
        }
    }, []);

    useEffect(() => {
        if (contentFile) {
            console.log(contentFile.type);
            setContentFileIsPDF(contentFile.type === "application/pdf");
            setContentDidChange(true);
        }
    }, [contentFile]);

    useEffect(() => {
        if (contentFileIsPDF) {
            putFormData({
                contentStorageMode : KNOWLEDGE_CONTENT_STRATEGY_AI_PROCESSED
            });
        }
    }, [contentFileIsPDF]);

    useEffect(() => {
        if (autoSubmitTrigger !== null) {
            handleSubmit();
        }
    }, [autoSubmitTrigger]);

    function processDocument(knowledgeDocument) {
        if (knowledgeDocument.hasOwnProperty("contentUpdateInterval")) {
            // Work out what values to populate the update interval fields with
            let updateInterval = knowledgeDocument.contentUpdateInterval;
            if (updateInterval) {
                let contentAutomaticUpdate = updateInterval > 0;

                let updateType = KNOWLEDGE_UPDATE_SCHEDULE_MINUTES;
                if (updateInterval >= KNOWLEDGE_UPDATE_SCHEDULE_DAYS) {
                    updateType = KNOWLEDGE_UPDATE_SCHEDULE_DAYS;
                } else if (updateInterval >= KNOWLEDGE_UPDATE_SCHEDULE_HOURS) {
                    updateType = KNOWLEDGE_UPDATE_SCHEDULE_HOURS;
                }
                setContentIntervalType(updateType);
                setContentUpdateIntervalMultiple(Math.round(updateInterval / updateType));

                knowledgeDocument.contentAutomaticUpdate = contentAutomaticUpdate;
            }
        }

        if (knowledgeDocument.hasOwnProperty("contentUrl")) {
            if (knowledgeDocument.contentUrl !== null) {
                setContentType(KNOWLEDGE_CONTENT_TYPE_URL);
            }
        }

        return knowledgeDocument;
    }

    function handleCallback(action, data) {
        if (callback) {
            callback(action, data);
        }
    }

    function handleSubmit(e) {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        if (isUploading) return;

        if (validateForm()) {
            if (contentType !== KNOWLEDGE_CONTENT_TYPE_PLAIN) {
                const content = CommonUtil.getOrDefault(formData, "content", "").trim();
                if (contentDidChange && content === "") {
                    if (isAIProcessing) return;

                    // Process content now...
                    submitContentForAIProcessing(true);
                    return;
                }
            }

            submitKnowledgeDocumentOverNetwork();
        }
    }

    function contentFileDidChange(e) {
        if (e.target.files.length > 0) {
            setContentFile(e.target.files[0]);
        }
    }

    function validateForm() {
        let out = true;
        const errors = {};

        const requiredFields = [
            { "key" : "name", "label" : "Title" }
        ];

        let storageMode = CommonUtil.getOrDefault(formData, "contentStorageMode", null);
        if (storageMode === KNOWLEDGE_CONTENT_STRATEGY_AI_PROCESSED) {
            if (CommonUtil.getOrDefault(formData, "contentAIPromptID", null) === null) {
                out = false;
                errors["contentAIPromptID"] = "Please select an AI Prompt";
            }

            if (contentType === KNOWLEDGE_CONTENT_TYPE_URL) {
                requiredFields.push(
                    { "key" : "contentUrl", "label" : "Content URL" }
                );
            } else if (contentType === KNOWLEDGE_CONTENT_TYPE_FILE) {
                if (!contentFile) {
                    out = false;
                    errors["contentFile"] = "Please provide a file";
                }
            }
        } else {
            requiredFields.push(
                { "key" : "content", "label" : "Content" }
            );
        }

        for (let i = 0; i < requiredFields.length; i++) {
            let problem = false;

            const field = requiredFields[i];
            if (formData.hasOwnProperty(field.key)) {
                if (formData[field.key] === undefined || formData[field.key] === null) {
                    problem = true;
                }
            } else {
                problem = true;
            }

            if (problem) {
                out = false;
                errors[field.key] = "Please provide value for '" + field.label + "'";
            }
        }

        return out;
    }

    function putFormData(data) {
        if (!CommonUtil.isObject(data)) {
            return;
        }

        const newFormData = CommonUtil.cloneObject(formData);
        const keys = Object.keys(data);

        keys.forEach((key) => {
            const newData = data[key];
            if (newData !== undefined) {
                newFormData[key] = data[key];
            } else {
                delete newFormData[key];
            }

            if (key === "contentUrl" || key === "contentStorageMode") {
                setContentDidChange(true);
                setAIProcessingError(null);
            }
        });

        setFormData(newFormData);
    }

    function handleBlur(event) {
        const name = event.target.name;
        const newTouched = CommonUtil.cloneObject(touched);
        newTouched[name] = true;
        setTouched(touched);
    }

    function handleChange(event) {
        const value = event.target.type === "checkbox" ? (event.target.checked ? 1 : 0) : event.target.value;

        let outData = {};
        outData[event.target.name] = value;
        putFormData(outData);
    }

    function pluginModalDidDismiss() {
        setModalOpen(false);
        setModalContent([]);
    }

    function showAIPromptSelector() {
        let modalContent = (
            <AIPromptsTable
                showSelectButton={true}
                onSelected={aiPromptWasSelected}
                onCancel={() => dismissAIPromptSelector()}
            />
        )

        setModalOpen(true);
        setModalContent(modalContent);
    }

    function dismissAIPromptSelector() {
        setModalOpen(false);
        setModalContent([]);
    }

    function aiPromptWasSelected(prompt) {
        if (prompt) {
            putFormData({
                contentAIPromptID : prompt.id,
                contentAIPromptName : prompt.title
            });
        }
        dismissAIPromptSelector()
    }

    function fetchTypesOverNetwork() {
        if (isLoadingTypes) return;
        setIsLoadingTypes(true);

        Axios.get(API.knowledgeDocumentType.getTypes, {})
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    const types = resp.data.knowledgeDocumentTypes;
                    if (types) {
                        const typeOptions = types.map((type) => {
                            return {
                                value : type.id.toString(),
                                label : type.name
                            }
                        });

                        typeOptions.unshift({
                            value : null,
                            label : "No Type"
                        });
                        setTypeOptions(typeOptions);
                    }
                } else {
                    console.log(API.formatError(resp));
                }
                setIsLoadingTypes(false);
            })
            .catch((e) => {
                console.log(e);
                setIsLoadingTypes(false);
            });
    }

    function fetchOrganisationsOverNetwork() {
        if (isLoadingOrganisations) return;
        setIsLoadingOrganisations(true);

        Axios.get(API.organisation.getOrganisations, {
            props : {
                appUsersOnly : 1
            }
        })
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    const organisations = resp.data.organisations;
                    if (organisations) {
                        const orgOptions = organisations.map((org) => {
                            return {
                                value : org.id.toString(),
                                label : org.name
                            }
                        });

                        orgOptions.unshift({
                            value : null,
                            label : "None (Global)"
                        });
                        setOrganisationOptions(orgOptions);
                    }
                } else {
                    console.log(API.formatError(resp));
                }
                setIsLoadingOrganisations(false);
            })
            .catch((e) => {
                console.log(e);
                setIsLoadingOrganisations(false);
            });
    }

    function submitKnowledgeDocumentOverNetwork(inFormData) {
        if (inFormData === undefined) {
            inFormData = formData;
        }

        if (isUploading) return;
        setIsUploading(true);

        setErrorMessage(null);

        const data = {
            organisationID : CommonUtil.getOrDefault(inFormData, "organisationID", null),
            name : CommonUtil.getOrDefault(inFormData, "name", ""),
            typeID: CommonUtil.getOrDefault(inFormData, "typeID", undefined),
            content : CommonUtil.getOrDefault(inFormData, "content", ""),
            refURL : CommonUtil.getOrDefault(inFormData, "refURL", undefined),
            contentAIPromptID : CommonUtil.getOrDefault(inFormData, "contentAIPromptID", undefined)
        };

        if (contentType === KNOWLEDGE_CONTENT_TYPE_URL) {
            data.contentUrl = CommonUtil.getOrDefault(inFormData, "contentUrl", null);
            data.contentStorageMode = CommonUtil.getOrDefault(inFormData, "contentStorageMode", null);
            data.contentUpdateInterval = contentUpdateIntervalMultiple * contentUpdateIntervalType;
        }

        let endpoint = API.knowledgeDocument.createKnowledgeDocument;
        let successMessage = "Successfully created Knowledge Document";
        if (knowledgeDocument) {
            data.id = knowledgeDocument.id;
            successMessage = "Successfully updated Knowledge Document";
            endpoint = API.knowledgeDocument.updateKnowledgeDocument;
        }

        Axios.post(endpoint, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    NotificationManager.success(successMessage);

                    handleCallback("submit");
                } else {
                    setErrorMessage(API.formatError(resp));
                }
                setIsUploading(false);
            })
            .catch((e) => {
                console.log(e);
                setIsUploading(false);
            });
    }

    function submitContentForAIProcessing(submitAfter) {
        if (isAIProcessing) return;
        setIsAIProcessing(true);

        setAIProcessingError(null);

        if (submitAfter === true) {
            setSubmitDelayProcessing(true);
        }

        const data = new FormData();

        const storageMode = CommonUtil.getOrDefault(formData, "contentStorageMode", KNOWLEDGE_CONTENT_STRATEGY_RAW);
        if (storageMode === KNOWLEDGE_CONTENT_STRATEGY_AI_PROCESSED) {
            let promptID = CommonUtil.getOrDefault(formData, "contentAIPromptID", undefined);
            if (promptID) {
                data.append("aiPromptID", promptID);
            }
        }

        if (contentType === KNOWLEDGE_CONTENT_TYPE_URL) {
            data.append("uploadType", "url");
            data.append("url", CommonUtil.getOrDefault(formData, "contentUrl", null));
        } else if (contentType === KNOWLEDGE_CONTENT_TYPE_FILE) {
            data.append("uploadType", "file");
            data.append("file", contentFile);
        }

        Axios.post(API.knowledgeDocument.processKnowledgeDocumentContent, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    if (resp.data.text) {
                        putFormData({
                            content : resp.data.text
                        });

                        setAIProcessingError({
                            success : true,
                            message : "Processing is complete. See summary below."
                        });

                        setContentDidChange(false);

                        if (submitAfter === true) {
                            setAutoSubmitTrigger(Math.random());
                        }
                    }
                } else {
                    console.log(API.formatError(r));

                    setAIProcessingError({
                        success : false,
                        message : API.formatError(r)
                    });
                }
                setIsAIProcessing(false);
            })
            .catch((e) => {
                console.log(e);
                setIsAIProcessing(false);

                setAIProcessingError({
                    success : false,
                    message : "AI Processing encountered an error. Please try again later."
                });
            });
    }

    // RENDER

    let modalTitle = "Create Knowledge Document";
    if (knowledgeDocument) {
        modalTitle = "Edit Knowledge Document";
    }

    let organisationPicker = [];
    if (PermissionUtil.allOrgs(PermissionUtil.PermissionCategories.knowledgeDocuments)) {
        if (knowledgeDocument && knowledgeDocument.id) {
            organisationPicker = (
                <div className="grid-x grid-padding-x">
                    <div className="small-3 cell">
                        <label htmlFor="organisationID" className="text-right middle">Organisation</label>
                    </div>
                    <div className={"small-9 cell " + (CommonUtil.getOrDefault(errors, "organisationID", null) && "error")}>
                        <input type={"text"} value={knowledgeDocument.organisationName} readOnly={true} />
                    </div>
                </div>
            )
        } else {
            organisationPicker = (
                <div className="grid-x grid-padding-x">
                    <div className="small-3 cell">
                        <label htmlFor="organisationID" className="text-right middle">Organisation</label>
                    </div>
                    <div
                        className={"small-9 cell " + (CommonUtil.getOrDefault(errors, "organisationID", null) && "error")}>
                        <ReactSelect
                            name="organisationID"
                            className="react-select"
                            onChange={handleChange}
                            value={CommonUtil.getOrDefault(formData, "organisationID", null)}
                            options={organisationOptions}
                            onBlur={handleBlur}
                        />
                        <small className="error">{CommonUtil.getOrDefault(errors, "organisationID", "")}</small>
                    </div>
                </div>
            )
        }
    }

    let typePicker = (
        <div className="grid-x grid-padding-x">
            <div className="small-3 cell">
                <label htmlFor="typeID" className="text-right middle">Type</label>
            </div>
            <div
                className={"small-9 cell " + (CommonUtil.getOrDefault(errors, "typeID", null) && "error")}>
                <ReactSelect
                    name="typeID"
                    className="react-select"
                    onChange={handleChange}
                    placeholder={"No Type"}
                    value={CommonUtil.getOrDefault(formData, "typeID", null)}
                    options={typeOptions}
                    onBlur={handleBlur}
                />
                <small className="error">{CommonUtil.getOrDefault(errors, "typeID", "")}</small>
            </div>
        </div>
    )


    let errorMessageElem = [];
    if (errorMessage) {
        errorMessageElem = (
            <div className={"alert alert-danger"}>
                {errorMessage}
            </div>
        );
    }

    const contentExtraFields = [];
    if (contentType !== KNOWLEDGE_CONTENT_TYPE_PLAIN) {
        let contentAIPromptElem = null;
        if (CommonUtil.getOrDefault(formData, "contentStorageMode", null) === KNOWLEDGE_CONTENT_STRATEGY_AI_PROCESSED) {
            let promptName = CommonUtil.getOrDefault(formData, "contentAIPromptName", "(No prompt selected)");

            contentAIPromptElem = (
                <div className="grid-x grid-padding-x">
                    <div className="small-3 cell">
                        <label htmlFor="text" className="text-right middle">
                            AI Prompt
                        </label>
                    </div>
                    <div className={"large-9 small-9 cell"}>
                        <div className={"input-group"}>

                            <input
                                type={"text"}
                                className={"input-group-field"}
                                disabled={true}
                                value={promptName}
                            />

                            <div className={"input-group-button"}>
                                <button
                                    className="button primary"
                                    type="button"
                                    onClick={showAIPromptSelector}
                                >
                                    Browse
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
            );
        }

        // SYNC/AI PROCESS BUTTON
        let processButtonLabel = "Sync now";
        if (isAIProcessing) {
            processButtonLabel = "Syncing, please wait"
        }

        let aiPromptTriggerButton = (
            <button
                className={"button primary"}
                onClick={submitContentForAIProcessing}
                disabled={isAIProcessing}
            >
                {processButtonLabel}
            </button>
        );
        if (CommonUtil.getOrDefault(formData, "contentStorageMode", KNOWLEDGE_CONTENT_STRATEGY_RAW) === KNOWLEDGE_CONTENT_STRATEGY_AI_PROCESSED) {
            processButtonLabel = "AI Process now";
            if (isAIProcessing) {
                processButtonLabel = "AI Processing..."
            }

            // Check to see if content has changed
            let processButtonDisabled = !contentDidChange;
            if (!processButtonDisabled) {
                // Check to see if an AI Prompt has been selected
                processButtonDisabled = CommonUtil.getOrDefault(formData, "contentAIPromptID", null) === null;
            }

            aiPromptTriggerButton = (
                <MagicButton
                    label={processButtonLabel}
                    animating={isAIProcessing}
                    disabled={processButtonDisabled}
                    onClick={submitContentForAIProcessing}
                />
            )
        }

        let messageElem = [];
        if (aiProcessingError !== null) {
            let messageClass = "error";
            if (aiProcessingError.success) {
                messageClass = "success";
            }

            messageElem = (
                <div className={"magic-button-error-container"}>
                    <div className={"magic-button-message " + messageClass}>
                        {aiProcessingError.message}
                    </div>
                </div>
            );
        }

        let aiPromptTriggerElem = (
            <div className="grid-x grid-padding-x">
                <div className="small-3 cell">
                    <label htmlFor="text" className="text-right middle"></label>
                </div>
                <div className={"large-9 small-9 cell"} style={{marginBottom : "10px"}}>
                    <div className={"magic-button-container"}>
                        {aiPromptTriggerButton}

                        {messageElem}
                    </div>
                </div>
            </div>
        );

        if (contentType === KNOWLEDGE_CONTENT_TYPE_URL) {
            contentExtraFields.push(
                <KnowledgeDocumentEditorTextInput
                    label={"Content URL"}
                    type={"text"}
                    name={"contentUrl"}
                    error={CommonUtil.getOrDefault(errors, "content", "")}
                    value={CommonUtil.getOrDefault(formData, "contentUrl", null)}
                    onChange={handleChange}
                    onBlur={handleBlur}/>
            )

            contentExtraFields.push(
                <div className="grid-x grid-padding-x">
                    <div className="small-3 cell">
                        <label htmlFor="contentType" className="text-right middle">Storage Mode</label>
                    </div>
                    <div
                        className={"small-9 cell " + (CommonUtil.getOrDefault(errors, "contentStorageMode", null) && "error")}>
                        <ReactSelect
                            name="contentStorageMode"
                            className="react-select"
                            onChange={handleChange}
                            value={CommonUtil.getOrDefault(formData, "contentStorageMode", KNOWLEDGE_CONTENT_STRATEGY_RAW)}
                            options={[
                                {
                                    value: KNOWLEDGE_CONTENT_STRATEGY_RAW,
                                    label: "Raw"
                                },
                                {
                                    value: KNOWLEDGE_CONTENT_STRATEGY_AI_PROCESSED,
                                    label: "AI Processed"
                                }
                            ]}
                            onBlur={handleBlur}
                        />
                        <small className="error">{CommonUtil.getOrDefault(errors, "contentType", "")}</small>
                    </div>
                </div>
            )

            if (contentAIPromptElem !== null) {
                contentExtraFields.push(contentAIPromptElem);
            }

            if (aiPromptTriggerElem !== null) {
                contentExtraFields.push(aiPromptTriggerElem);
            }

            let automaticUpdate = CommonUtil.getOrDefault(formData, "contentAutomaticUpdate", false);
            automaticUpdate = automaticUpdate === true || parseInt(automaticUpdate) === 1;

            contentExtraFields.push(
                <div className="grid-x grid-padding-x">
                    <div className="small-3 cell">
                        <label htmlFor="contentType" className="text-right middle">Automatic updates</label>
                    </div>
                    <div className={"small-9 cell"}>
                        <label className="chkbx-container">
                            <input
                                type={"checkbox"}
                                checked={automaticUpdate}
                                name={"contentAutomaticUpdate"}
                                onChange={handleChange}
                            />

                            <span className="chkmk"></span>
                        </label>
                        <p>If the page you are including changes regularly, the page can be automatically updated on a regular basis.</p>
                    </div>
                </div>
        )

            if (automaticUpdate) {
                contentExtraFields.push(
                    <div className="grid-x grid-padding-x">
                        <div className="small-3 cell">
                            <label htmlFor="contentType" className="text-right middle">Update Frequency</label>
                        </div>

                        <div
                            className={"small-4 cell " + (CommonUtil.getOrDefault(errors, "contentUpdateIntervalType", null) && "error")}>
                            <input
                                type={"number"}
                                name={"contentUpdateIntervalMultiple"}
                                value={contentUpdateIntervalMultiple}
                                onChange={(e) => {
                                    setContentUpdateIntervalMultiple(e.target.value);
                                }}
                            />
                        </div>

                        <div
                            className={"small-5 cell " + (CommonUtil.getOrDefault(errors, "contentUpdateIntervalType", null) && "error")}>
                            <ReactSelect
                                name="contentUpdateIntervalType"
                                className="react-select"
                                onChange={(e) => setContentIntervalType(e.target.value)}
                                value={contentUpdateIntervalType}
                                options={[
                                    {
                                        value: KNOWLEDGE_UPDATE_SCHEDULE_MINUTES,
                                        label: "Minutes"
                                    },
                                    {
                                        value: KNOWLEDGE_UPDATE_SCHEDULE_HOURS,
                                        label: "Hours"
                                    },
                                    {
                                        value: KNOWLEDGE_UPDATE_SCHEDULE_DAYS,
                                        label: "Days"
                                    }
                                ]}
                                onBlur={handleBlur}
                            />
                            <small className="error">{CommonUtil.getOrDefault(errors, "contentType", "")}</small>
                        </div>
                    </div>
                )
            }

        } else {
            contentExtraFields.push(
                <div className="grid-x grid-padding-x">
                    <div className="small-3 cell">
                        <label htmlFor="contentType" className="text-right middle">File</label>
                    </div>
                    <div
                        className={"small-9 cell " + (CommonUtil.getOrDefault(errors, "contentFile", null) && "error")}>
                        <input
                            type={"file"}
                            accept={"text/html, application/pdf"}
                            onChange={contentFileDidChange}
                        />
                        <small className="error">{CommonUtil.getOrDefault(errors, "contentType", "")}</small>
                    </div>
                </div>
            )

            const storageOptions = [
                {
                    value: KNOWLEDGE_CONTENT_STRATEGY_AI_PROCESSED,
                    label: "AI Processed"
                }
            ];

            if (!contentFileIsPDF) {
                storageOptions.unshift({
                    value: KNOWLEDGE_CONTENT_STRATEGY_RAW,
                    label: "Raw"
                })
            }

            contentExtraFields.push(
                <div className="grid-x grid-padding-x">
                    <div className="small-3 cell">
                        <label htmlFor="contentType" className="text-right middle">Storage Mode</label>
                    </div>
                    <div
                        className={"small-9 cell " + (CommonUtil.getOrDefault(errors, "contentStorageMode", null) && "error")}>
                        <ReactSelect
                            name="contentStorageMode"
                            className="react-select"
                            onChange={handleChange}
                            value={CommonUtil.getOrDefault(formData, "contentStorageMode", KNOWLEDGE_CONTENT_STRATEGY_RAW)}
                            options={storageOptions}
                            onBlur={handleBlur}
                        />
                        <small className="error">{CommonUtil.getOrDefault(errors, "contentType", "")}</small>
                    </div>
                </div>
            )

            if (contentAIPromptElem !== null) {
                contentExtraFields.push(contentAIPromptElem);
            }

            if (aiPromptTriggerElem !== null) {
                contentExtraFields.push(aiPromptTriggerElem);
            }
        }

    }

    let submitLabel = "Submit";
    if (submitDelayProcessing) {
        submitLabel = "Processing, please wait...";
    }

    return (
        <div className={"knowledge-document-editor"}>
            <ModalProgress show={isUploading}/>
            <div className={"modal-head"}>
                <div className={"modal-head"}>
                    <h3>{modalTitle}</h3>
                </div>
            </div>

            <form onSubmit={handleSubmit}>
                <div className={"modal-body"}>

                    {organisationPicker}

                    {typePicker}

                    <KnowledgeDocumentEditorTextInput
                        label={"* Title"}
                        name={"name"}
                        error={CommonUtil.getOrDefault(errors, "name", "")}
                        value={CommonUtil.getOrDefault(formData, "name", "")}
                        onChange={handleChange}
                        onBlur={handleBlur}/>

                    <KnowledgeDocumentEditorTextInput
                        label={"Key"}
                        name={"key"}
                        readonly={true}
                        placeholder={"(Automatically Generated)"}
                        value={CommonUtil.getOrDefault(formData, "key", "")}/>

                    <div className="grid-x grid-padding-x">
                        <div className="small-3 cell">
                            <label htmlFor="contentType" className="text-right middle">Type of Content</label>
                        </div>
                        <div
                            className={"small-9 cell " + (CommonUtil.getOrDefault(errors, "contentType", null) && "error")}>
                            <ReactSelect
                                name="contentType"
                                className="react-select"
                                onChange={(e) => setContentType(e.target.value)}
                                value={contentType}
                                options={[
                                    {
                                        value : KNOWLEDGE_CONTENT_TYPE_PLAIN,
                                        label : "Plain text"
                                    },
                                    {
                                        value : KNOWLEDGE_CONTENT_TYPE_URL,
                                        label : "Website URL"
                                    },
                                    {
                                        value : KNOWLEDGE_CONTENT_TYPE_FILE,
                                        label : "File upload"
                                    }
                                ]}
                                onBlur={handleBlur}
                            />
                            <small className="error">{CommonUtil.getOrDefault(errors, "contentType", "")}</small>
                        </div>
                    </div>

                    {contentExtraFields}

                    <KnowledgeDocumentEditorTextInput
                        label={"* Content"}
                        type={"textarea"}
                        name={"content"}
                        error={CommonUtil.getOrDefault(errors, "content", "")}
                        value={CommonUtil.getOrDefault(formData, "content", "")}
                        onChange={handleChange}
                        onBlur={handleBlur}/>

                    <KnowledgeDocumentEditorTextInput
                        label={"Reference URL"}
                        name={"refURL"}
                        error={CommonUtil.getOrDefault(errors, "refURL", "")}
                        value={CommonUtil.getOrDefault(formData, "refURL", "")}
                        onChange={handleChange}
                        onBlur={handleBlur}/>

                    {errorMessageElem}

                </div>

                <div className="modal-footer">
                    <div className="grid-x grid-padding-x">
                        <div className="small-12 cell">
                            <div className="btn-wrap">
                                {
                                    <React.Fragment>
                                        <button
                                            className="button secondary"
                                            type="button"
                                            onClick={() => handleCallback("submit")}
                                        >
                                            Cancel
                                        </button>

                                        <button
                                            className="button success"
                                            type="submit"
                                            disabled={!validateForm() || submitDelayProcessing}
                                        >
                                            {submitLabel}
                                        </button>
                                    </React.Fragment>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </form>

            <Modal
                classNames={{modal: "modal-medium"}}
                open={modalOpen}
                modalId="PluginModal"
                onClose={pluginModalDidDismiss}
                center
            >
                {modalContent}
            </Modal>
        </div>
    )

}

const KnowledgeDocumentEditorTextInput = (props) => {

    const {label} = props;
    const {name} = props;
    const {type} = props;
    const {value} = props;
    const {error} = props;
    const {onChange} = props;
    const {onBlur} = props;
    const {readonly} = props;
    const {placeholder} = props;

    let inputElem = (
        <input
            type={type ? type : "text"}
            name={name}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            readOnly={readonly}
            placeholder={placeholder}
        />
    )

    if (type === "textarea") {
        inputElem = (
            <textarea
                name={name}
                value={value}
                rows={10}
                onChange={onChange}
                onBlur={onBlur}
                readOnly={readonly}
                placeholder={placeholder}
            />
        );
    }

    return (
        <div className="grid-x grid-padding-x">
            <div className="small-3 cell">
                <label htmlFor="name" className="text-right middle ">{label}</label>
            </div>
            <div className={"small-9 cell " + (error && "error")}>
                {inputElem}

                <small className="error">{error}</small>
            </div>
        </div>
    )

}