import React from 'react';
import AppComponent from "../../common/AppComponent";
import PageContent from "../../common/PageContent";
import {AIPromptsTable} from "./AIPromptsTable";

export default class AIPrompts extends AppComponent {

    pathElements = [
        { id : 0, title : "Home", url : "/" },
        { id : 1, title : "Chat Flows", url : "/chatflows" },
        { id : 2, title : "AI Prompts" }
    ]

    render() {
        return (
            <PageContent pathElements={this.pathElements}>
                <AIPromptsTable />
            </PageContent>
        )
    }

}