import React from 'react';

import "./MagicButton.css";

import iconWand from "../../assets/img/magicbutton/wand.svg";
import iconStars from "../../assets/img/magicbutton/stars.svg";
import iconSpinner from "../../assets/img/magicbutton/spinner_stars_2.svg";

export const MagicButton = (props) => {

    const {label} = props;
    const {animating} = props;
    const {disabled} = props;
    const {onClick} = props;

    let useIcon = iconWand;
    let extraClass = "";
    if (animating === true) {
        useIcon = iconSpinner;
        extraClass = " ep-magic-button-animated";
    }

    const starStyle = { backgroundImage : "url(" + iconStars + ")"};

    let useOnClick = onClick;
    if (disabled) {
        extraClass += " ep-magic-button-disabled";
        useOnClick = null;
    }

    return (
        <div className={"ep-magic-button" + extraClass} onClick={useOnClick}>
            <div className={"ep-magic-button-icon"} style={{backgroundImage: "url(" + useIcon + ")"}}/>
            <div className={"ep-magic-button-text"}>{label}</div>

            <div className={"ep-magic-button-star itr1"} style={starStyle}/>
            <div className={"ep-magic-button-star itr2"} style={starStyle}/>
            <div className={"ep-magic-button-star itr3"} style={starStyle}/>
            <div className={"ep-magic-button-star itr4"} style={starStyle}/>
            <div className={"ep-magic-button-star itr5"} style={starStyle}/>
            <div className={"ep-magic-button-star itr6"} style={starStyle}/>
        </div>
    )

}