import React from 'react'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'

import AppProvider from 'contexts/AppProvider'
import { NotificationContainer } from 'react-notifications'

import './assets/css/foundation.css'
import 'react-table/react-table.css'
import 'react-notifications/lib/notifications.css'
import 'flatpickr/dist/themes/light.css'
import 'react-sortable-tree/style.css';
import 'react-bubble-chart/src/style.css';
import 'react-block-ui/style.css';
import './assets/css/main.css'
import "./plugin-cms.css";
import 'react-dates/lib/css/_datepicker.css';


import HomeContainer from 'components/home/HomeContainer'
import AdminUsers from 'components/adminUsers/AdminUsers'
import AppUsers from 'components/appUsers/AppUsers'
import Posts from 'components/posts/Posts'
import MoneyOffers from 'components/money/offers/MoneyOffers'
import Money from 'components/money/Money'
import Login from 'components/login/Login'
import NavBar from 'components/common/NavBar'
import Habits from 'components/habits/Habits'
import Support from 'components/support/Support'
import NeedHelpNow from 'components/needHelpNow/NeedHelpNow'
import PrivateRoute from 'router/PrivateRoute'
import { AppContext } from 'contexts/AppContext'
import SectionEditor from 'components/sectionConfig/SectionEditor'
import Services from 'components/services/Services'
import MoneyStructure from 'components/money/structure/MoneyStructure'
import Organisation from 'components/organisation/Organisation'
import Departments from 'components/organisation/departments/Departments'
import Domains from 'components/organisation/domains/Domains'
import Campuses from 'components/organisation/campuses/Campuses'
import Courses from 'components/organisation/courses/Courses'
import Organisations from 'components/organisation/organisations/Organisations'
import ForgottenPassword from 'components/login/ForgottenPassword'
import ResetPassword from 'components/login/ResetPassword'
import AppConfigs from 'components/organisation/appConfigs/AppConfigs'
import Onboarding from 'components/onboarding/Onboarding'
import ServicePageEditor from 'components/services/ServicePageEditor'
import UnsupportedBrowser from 'components/browser/UnsupportedBrowser'
import Challenges from 'components/challenges/Challenges'
import ChallengeEdit from 'components/challenges/ChallengeEdit'
import Awards from 'components/awards/Awards'
import StudentCategories from 'components/organisation/studentCategories/StudentCategories'
import DegreeTypes from 'components/organisation/degreeTypes/DegreeTypes'
import Actions from 'components/cta/Actions'
import APIKeys from 'components/organisation/apiKeys/APIKeys'
import Reports from 'components/reports/Reports'
import Podcasts from 'components/podcasts/Podcasts'
import ChatFlows from 'components/chatflows/ChatFlows'
import WellbeingScore from 'components/organisation/wellbeingScore/WellbeingScore'
import ChatTriggersPage from 'components/chatflows/ChatTriggersPage'
import ChatTinyHabitsPage from 'components/chatflows/ChatTinyHabitsPage'
import Emotions from 'components/emotions/Emotions'
import ChatImportPage from 'components/chatflows/ChatImportPage'


// We need that for react-dates styling
// https://github.com/airbnb/react-dates
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faTrash, faEdit, faEye, faInfoCircle, faTimesCircle, faPlus, faChartBar, faChartLine, faFilePdf, faFileExcel, faClone } from '@fortawesome/free-solid-svg-icons'
import ChatFlowEditor from "./components/chatflows/ChatFlowEditor";
import UWBPlugin from "./components/plugin/UWBPlugin";
import {PluginChatFlowTable} from "./components/plugin/PluginChatFlowTable";
import {PluginSupportTable} from "./components/plugin/PluginSupportTable";
import {Toast} from "./utils/ToastUtil";
import {AccessKeys} from "./components/organisation/accessKeys/AccessKeys";
import AIPrompts from "./components/chatflows/aiPrompts/AIPrompts";

library.add(faTrash, faEdit, faEye, faInfoCircle, faTimesCircle, faPlus, faChartBar, faChartLine, faFilePdf, faFileExcel, faClone);


class App extends React.Component {

    toastTimeout = undefined;

    constructor(props) {
        super(props);

        window._uwbToast = (id, text, duration, type) => {
            if (duration === undefined) {
                duration = Toast.DURATION_LONG;
            }

            this.setState({
                uwbToastShown : true,
                uwbToastDismissing : false,
                uwbToastId : id,
                uwbToastMessage : text,
                uwbToastDuration : duration,
                uwbToastType : type
            });
        }

        this.state = {
            selectedSection: null,
            isIE: /*@cc_on!@*/false || !!document.documentMode
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.uwbToastShown !== this.state.uwbToastShown && this.state.uwbToastShown === true) {
            clearTimeout(this.toastTimeout);
            this.toastTimeout = setTimeout(() => {
                this.setState({
                    uwbToastDismissing : true
                });
            }, this.state.uwbToastDuration);
        }

        if (prevState.uwbToastDismissing !== this.state.uwbToastDismissing && this.state.uwbToastDismissing === true) {
            clearTimeout(this.toastTimeout);
            this.toastTimeout = setTimeout(() => {
                this.setState({
                    uwbToastShown : false
                });
            }, 200);
        }
    }

    forceDismissToast = () => {
        this.setState({
            uwbToastDismissing : true
        });
    }

    handleRouteChange = (menu, prevRoute, nextRoute) => {
        this.setState({
            selectedSection: menu.find((s) => s.link === nextRoute)
        })
    }

    render() {
        if (this.state.isIE) {
            return <UnsupportedBrowser />;
        }

        // CADE 19/08/2020 - I was asked to remove the App Users section from the CMS. Here is the route definition if you need it again.
        // <PrivateRoute exact path="/appUsers" component={AppUsers}/>

        let toastElem = [];
        if (this.state.uwbToastShown === true) {
            let classExtra = "";
            let keyExtra = "";
            if (this.state.uwbToastDismissing) {
                classExtra += " dismissing";
                keyExtra = "-dismiss";
            }

            if (this.state.uwbToastType === Toast.TYPE_SUCCESS) {
                classExtra += " success";
            } else if (this.state.uwbToastType === Toast.TYPE_ERROR) {
                classExtra += " error";
            }

            toastElem = (
                <div className={"toast-container"} onClick={() => this.forceDismissToast()}>
                    <div className={"toast-message" + classExtra} key={this.state.uwbToastId + keyExtra}>
                        {this.state.uwbToastMessage}
                    </div>
                </div>
            );
        }

        return (
            <AppProvider>
                <AppContext.Consumer>
                    {context =>
                        <Router onChange={({...props}) => this.onRouteChange(context.menu, props)}>
                            {context.user !== null &&
                            <NavBar sections={context.menu} selectedSection={this.state.selectedSection}/>
                            }
                            <div style={{paddingTop: 60}}>
                                <Switch>
                                    <Route exact path="/login" component={Login} />
                                    <Route exact path="/login/forgottenPassword" component={ForgottenPassword} />
                                    <Route exact path="/login/resetPassword" component={ResetPassword} />
                                    <PrivateRoute exact path="/" component={HomeContainer}/>
                                    <PrivateRoute exact path="/adminUsers" component={AdminUsers}/>
                                    <PrivateRoute exact path="/posts" component={Posts}/>

                                    <PrivateRoute exact path="/habits" component={Habits} />
                                    <PrivateRoute exact path="/money" component={Money} />
                                    <PrivateRoute exact path="/money/offers" component={MoneyOffers}/>
                                    <PrivateRoute exact path="/support" component={Support} />
                                    <PrivateRoute exact path="/support/create" component={SectionEditor} />
                                    <PrivateRoute exact path="/support/edit" component={SectionEditor} />
                                    <PrivateRoute exact path="/needHelpNow" component={NeedHelpNow} />
                                    <PrivateRoute exact path="/needHelpNow/create" component={SectionEditor} />
                                    <PrivateRoute exact path="/needHelpNow/edit" component={SectionEditor} />
                                    <PrivateRoute exact path="/money/structure" component={MoneyStructure}/>
                                    <PrivateRoute exact path="/money/structure/create" component={SectionEditor} />
                                    <PrivateRoute exact path="/money/structure/edit" component={SectionEditor} />
                                    <PrivateRoute exact path="/services" component={Services} />
                                    <PrivateRoute exact path="/services/createPage" component={ServicePageEditor} />
                                    <PrivateRoute exact path="/services/editPage" component={ServicePageEditor} />
                                    <PrivateRoute exact path="/organisation" component={Organisation} />
                                    <PrivateRoute exact path="/organisation/departments" component={Departments} />
                                    <PrivateRoute exact path="/organisation/domains/" component={Domains} />
                                    <PrivateRoute exact path="/organisation/campuses" component={Campuses} />
                                    <PrivateRoute exact path="/organisation/courses" component={Courses} />
                                    <PrivateRoute exact path="/organisation/organisations" component={Organisations} />
                                    <PrivateRoute exact path="/organisation/appConfigs" component={AppConfigs} />
                                    <PrivateRoute exact path="/organisation/wellbeingScore" component={WellbeingScore} />
                                    <PrivateRoute exact path="/organisation/onboarding" component={Onboarding} />
                                    <PrivateRoute exact path="/organisation/apiKeys" component={APIKeys} />
                                    <PrivateRoute exact path="/organisation/accessKeys" component={AccessKeys} />
                                    <PrivateRoute exact path="/challenges" component={Challenges} />
                                    <PrivateRoute exact path="/challenges/createChallenge" component={ChallengeEdit} />
                                    <PrivateRoute exact path="/challenges/editChallenge" component={ChallengeEdit} />
                                    <PrivateRoute exact path="/awards" component={Awards} />
                                    <PrivateRoute exact path="/organisation/studentCategories" component={StudentCategories} />
                                    <PrivateRoute exact path="/organisation/degreeTypes" component={DegreeTypes} />
                                    <PrivateRoute exact path="/cta" component={Actions} />
                                    <PrivateRoute exact path="/reports" component={Reports} />
                                    <PrivateRoute exact path="/podcasts" component={Podcasts} />
                                    <PrivateRoute exact path="/chatFlows" component={ChatFlows} />
                                    <PrivateRoute exact path="/chatFlows/create" component={ChatFlowEditor} />
                                    <PrivateRoute exact path="/chatFlows/edit" component={ChatFlowEditor} />
                                    <PrivateRoute exact path="/chatFlows/triggers" component={ChatTriggersPage} />
                                    <PrivateRoute exact path="/chatFlows/tinyHabits" component={ChatTinyHabitsPage} />
                                    <PrivateRoute exact path="/chatFlows/import" component={ChatImportPage} />
                                    <PrivateRoute exact path={"/chatFlows/aiPrompts"} component={AIPrompts} />
                                    <PrivateRoute exact path="/emotions" component={Emotions} />
                                    <PrivateRoute exact path={"/plugin"} component={UWBPlugin} />
                                    <PrivateRoute exact path={"/plugin/chatFlows"} component={PluginChatFlowTable} />
                                    <PrivateRoute exact path={"/plugin/support"} component={PluginSupportTable} />
                                    <PrivateRoute exact path="/plugin/support/create" component={SectionEditor} />
                                    <PrivateRoute exact path="/plugin/support/edit" component={SectionEditor} />
                                    <PrivateRoute exact path="/plugin/support-inh/create" component={SectionEditor} />
                                    <PrivateRoute exact path="/plugin/support-inh/edit" component={SectionEditor} />
                                </Switch>
                            </div>
                            <NotificationContainer />
                        </Router>
                    }
                </AppContext.Consumer>

                {toastElem}

            </AppProvider>
        );
    }
}

export default App;