import React, {useEffect, useRef, useState} from 'react';
import DateHelper from "../../../utils/DateHelper";
import {Button, ButtonGroup, Colors, Sizes} from "react-foundation";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ReactTable from "react-table";
import SearchBar from "../../common/SearchBar";
import TableContainer from "../../common/TableContainer";
import Modal from "react-responsive-modal";
import {ContextUtil} from "../../../utils/ContextUtil";
import {PermissionUtil} from "../../../utils/PermissionUtil";
import Axios from "axios";
import {API} from "../../../api/APIClient";
import DatatableHelper from "../../../utils/DatatableHelper";
import StandardModal from "../../common/StandardModal";
import {CommonUtil} from "../../../utils/CommonUtil";
import {AIPromptForm} from "./AIPromptForm";
import {NotificationManager} from "react-notifications";

export const AIPromptsTable = (props) => {

    const {showSelectButton} = props;
    const {onSelected} = props;
    const {onCancel} = props;

    const [items, setItems] = useState([]);
    const [searchKey, setSearchKey] = useState("");
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(props.pageSize || 10);
    const [sorted, setSorted] = useState([{id : "id", desc : true}]);
    const [totalPages, setTotalPages] = useState(-1);
    const [isLoading, setIsLoading] = useState(false);
    const [modal, setModal] = useState({ isOpen : false, content : null, isUploading : false, error : null });
    const [isUploading, setIsUploading] = useState(false);

    const searchKeyword = useRef("");

    const searchTimer = useRef();

    useEffect(() => {
        fetchItems();
    }, []);

    useEffect(() => {
        fetchItems()
    }, [page, pageSize, sorted, searchKey]);

    function searchTextDidChange(text) {
        clearTimeout(searchTimer.current);
        searchTimer.current = setTimeout(() => {
            searchKeyword.current = text;
            setSearchKey(text);
        }, 1000);
    }

    function showEditor(id) {
        console.log("showEditor", id);
        const modalContent = (
            <AIPromptForm
                promptId={id}
                callback={(action, data) => {
                    if (action === "submit") {
                        closeModal();
                        fetchItems();
                    }
                }}
            />
        );

        openModal(
            modalContent,
            "modal-medium"
        );
    }

    function fetchItems() {
        if (isLoading) return;
        setIsLoading(true);

        const formData = {
            page,
            pageSize,
            sorted,
            filters: {
                search: searchKeyword.current
            }
        };

        Axios.post(API.aiPrompts.getAIPromptsDT, formData)
            .then((res) => {
                if (res.data.success) {
                    setItems(res.data.data.rows);
                    setTotalPages(DatatableHelper.getTotalPages(res.data.data));

                    if (props.onDidFetch instanceof Function) {
                        props.onDidFetch();
                    }
                } else {
                    console.log("Network error", res);
                }

                setIsLoading(false);
            })
            .catch((e) => {
                console.log(e);

                setIsLoading(false);
            });
    }

    function toggleRow(item) {
        if (props.onSelectedRow instanceof Function) {
            props.onSelectedRow(item);
        }
    }

    function toggleSelectAll() {
        if (props.onSelectedAll instanceof Function) {
            props.onSelectedAll(!props.selectAll, items);
        }
    }

    function showDeleteModal(item) {
        console.log("DELETE", item);

        if (!modal.isOpen) {
            const content = (
                <StandardModal
                    title={"Delete AI Prompt"}
                    content={(
                        <div>Are you sure you want to delete the AI Prompt "<strong>{item.title}</strong>"? This action cannot be undone.</div>
                    )}
                    buttons={(
                        <>
                            <button className={"button secondary"} onClick={closeModal}>Cancel</button>
                            <button className={"button alert"} onClick={() => handleDeleteItem(item)}>Delete</button>
                        </>
                    )}
                    isUploading={isUploading}
                />
            )

            openModal(content, "modal-medium");
        }
    }

    function openModal(content, size, isUploading, error) {
        if (isUploading === undefined) {
            isUploading = false;
        }

        const newModal = CommonUtil.cloneObject(modal);
        newModal.isOpen = true;
        newModal.content = content;
        newModal.size = size;
        newModal.isUploading = isUploading;
        newModal.error = error;

        setModal(newModal);
    }

    function closeModal() {
        const newModal = CommonUtil.cloneObject(modal);
        newModal.isOpen = false;
        newModal.content = null;
        setModal(newModal);
    }

    function handleDeleteItem(item) {
        closeModal();
        if (isUploading) return;

        if (item) {
            setIsUploading(true);

            const data = new FormData();
            data.append("id", item.id);

            Axios.post(API.aiPrompts.deleteAIPrompt, data)
                .then((r) => {
                    const resp = API.parse(r);
                    if (resp.success) {
                        NotificationManager.success("Successfully removed item");
                        fetchItems();
                    } else {
                        console.log(API.formatError(resp));
                    }
                    setIsUploading(false);
                })
                .catch((e) => {
                    console.log(e);
                    setIsUploading(false);
                });
        }
    }

    // RENDER

    const columns = [
        {
            id: "checkbox",
            accessor: "",
            Cell: row => {
                return (
                    <input
                        type="checkbox"
                        className="checkbox"
                        checked={!!props.selected.find((o) => o.id === row.original.id)}
                        onChange={() => toggleRow(row.original)}
                    />
                );
            },
            Header: () => {
                if (props.isSingleSelection) {
                    return null;
                }
                else {
                    return (
                        <input
                            type="checkbox"
                            className="checkbox"
                            checked={props.selectAll}
                            onChange={toggleSelectAll}
                        />
                    );
                }
            },
            sortable: false,
            width: 45,
            show: props.isSelector || false
        },
        {
            Header: 'ID',
            accessor: 'id',
            maxWidth: 60
        },
        {
            Header: "Title",
            accessor: "title"
        },
        {
            Header: "Content",
            accessor: "content",
            // Cell: row => {
            //     return row.value === 1 ? "Yes" : "No";
            // }
        },
        {
            Header: 'Created At',
            accessor: 'createdAt',
            maxWidth: 180,
            Cell: row => {
                return DateHelper.convertToLocalDateTime(row.value);
            }
        },
        {
            Header: 'Actions',
            Cell: row => {
                if (showSelectButton) {
                    return (
                        <ButtonGroup size={Sizes.TINY}>
                            <Button color={Colors.PRIMARY} onClick={() => {
                                if (onSelected) {
                                    onSelected(row.original);
                                }
                            }}>
                                Select
                            </Button>
                        </ButtonGroup>
                    )
                } else {
                    return (
                        <ButtonGroup size={Sizes.TINY}>
                            <Button color={Colors.PRIMARY} onClick={() => showEditor(row.original.id)}><FontAwesomeIcon
                                icon="edit"/></Button>
                            <Button color={Colors.ALERT} onClick={() => showDeleteModal(row.original)}><FontAwesomeIcon
                                icon="trash"/></Button>
                        </ButtonGroup>
                    )
                }
            },
            maxWidth: 100,
            sortable: false,
            className: "center",
            show: !props.isSelector && (PermissionUtil.edit("chatFlows")|| PermissionUtil.delete("chatFlows"))
        }
    ]

    const table = (
        <ReactTable
            columns={columns}
            data={items}
            pages={totalPages}
            pageSize={pageSize}
            loading={isLoading}
            multisort={true}
            manual
            className='-striped'
            onFetchData={(state) => {
                setPage(state.page);
                setPageSize(state.pageSize);
                setSorted(state.sorted);
            }}
        />
    );

    const filters = (
        <>
            <div className="cell small-12 medium-6 large-3">
                <span>Search</span>
                <SearchBar
                    onDidChange={(newValue) => searchTextDidChange(newValue)}
                />
            </div>
        </>
    )

    let buttons = [];
    if (!showSelectButton) {
        buttons = (
            <>
                {PermissionUtil.create("chatFlows") && !props.isSelector &&
                    <>
                        <button
                            type="button"
                            className="button small"
                            onClick={() => {
                                showEditor();
                            }}>
                            Add New AI Prompt
                        </button>
                    </>
                }
            </>
        )
    }

    return (
        <>
            <TableContainer
                title="AI Prompts"
                buttons={buttons}
                filters={filters}
                table={table}
            />

            <Modal
                classNames={{modal: modal.size}}
                open={modal.isOpen}
                modalId="AIPromptTableModal"
                onClose={closeModal}
                center
            >
                {modal.content}
            </Modal>

        </>
    );

}